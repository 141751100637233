import * as React from 'react'
import { Fragment, useState, useRef, useEffect } from 'react'
import Header from '../components/header'
import Footer from '../components/footer'
import "../style/home.less"
import { navigate } from 'gatsby'
import datasImg from "../images/home/datas.png"
// import video from "../images/home/video.png"
import { Link } from 'gatsby'
import arrowRight from "../images/home/arrow_right.png"
import shield from "../images/home/adv-icon1.png"
import lock from "../images/home/adv-icon2.png"
import cube from "../images/home/adv-icon3.png"
import partnersImg1 from "../images/home/partnersImg1.png"
import partnersImg2 from "../images/home/partnersImg2.png"
import partnersImg3 from "../images/home/partnersImg3.png"
import partnersImg4 from "../images/home/partnersImg4.png"
import partnersImg5 from "../images/home/rcslogo.png"
import robot from "../images/home/robot.png"
import concave from "../images/home/concave.png"
import group1 from "../images/home/Group1.png"
import group2 from "../images/home/Group2.png"
import group3 from "../images/home/Group3.png"
import group4 from "../images/home/Group4.png"
import react from "../images/home/icon_react.png"
import docker from "../images/home/icon_docker.png"
import ethereum from "../images/home/icon_ethereum.png"
import keycloak from "../images/home/icon_keycloak.png"
import kubernetes from "../images/home/icon_kubernetes.png"
import presto from "../images/home/icon_presto.png"
import solidity from "../images/home/icon_solidity.png"
import customers1 from "../images/home/customers1.png"
import customers2 from "../images/home/customers2.png"
import customers3 from "../images/home/customers3.png"
import arrowBlue from "../images/home/arrow-blue.png"
import arrowGrey from "../images/home/arrow-grey.png"
import shieldImg from "../images/home/shield-img.png"
import smartHome from "../images/home/smart-home.png"
import phone from "../images/home/phone.png"
import footLogo from "../images/home/foot-logo.png"
import video from "../video/video11.mp4"
import robotImg1 from "../images/home/robot1.png"
import robotImg2 from "../images/home/robot2.png"
import robotImg3 from "../images/home/robot3.png"
import videoButton from "../images/home/videobutton.png"
import Fade from "react-reveal/Fade"
import popupbg from '../images/popup.png'
import colse from '../images/close.png'
const IndexPage = () => {
  const [pressButton, setPressButton] = useState(null)
  const productArr = [
    { img: shield, text: "Contribute to open source", p: "Bringing technology from the open source community and preparing it for the enterprise benefits many companies that are adapting to the demands of modern digital business." },
    { img: lock, text: "Data Trust product", p: "Bringing technology from the open source community and preparing it for the enterprise benefits many companies that are adapting to the demands of modern digital business." },
    { img: cube, text: "One-stop data platform", p: "It is interconnected with the existing business system of the enterprise, which can quickly connect with other functional departments and improve the efficiency of data sharing." }
  ]
  const phoneIcon = [
    presto, solidity, keycloak, react, ethereum, docker, kubernetes
  ]
  const securelyCollaborateArr = [
    {
      id: 0,
      img: robotImg1,
      title: "Break down data silos",
      text: "Our platform enables broad data collaboration to solve complex problems"
    },
    {
      id: 1,
      img: robotImg2,
      title: "Retain ownership and control",
      text: "Our platform lets you own, control, trade and drive value from your data"
    },
    {
      id: 2,
      img: robotImg3,
      title: "Data access management",
      text: "Define and enforce precision access rights on datasets via smart contracts"
    }
  ]
  const partnersImgArr = [partnersImg1, partnersImg2, partnersImg3, partnersImg4, partnersImg5]
  const technologyArr = [
    { id: 0, img: group1, h3: "Big Data Management", p: "Automation, data traceability and distributed identity" },
    { id: 1, img: group2, h3: "AI Analytics Open Container", p: "Distributed AI app development" },
    { id: 2, img: group3, h3: "Zero Trust SD-WAN", p: "Automation of data compliance and governance" },
    { id: 3, img: group4, h3: "Distributed Ledger & Smart Contracts", p: "Automation, data traceability and distributed identity" }
  ]
  const iconArrTop = [react, ethereum, docker, presto, solidity]
  const iconArrBottom = [keycloak, kubernetes]
  const satisfiedCustomersArr = [
    { img: customers1, h3: "Smart Cities Toronto", p: "The City of Toronto contracted Sightline Innovation to explore the use of Datatrust technology for various Connected Community use cases." },
    { img: customers2, h3: "Public Health Agency of Canada", p: "PHAC leveraged the Sightline Datatrust and AI platform technology to monitor mental health and behavioural patterns." },
    { img: customers3, h3: "Public Health Agency of Canada", p: "PHAC leveraged the Sightline Datatrust and AI platform technology to monitor mental health and behavioural patterns." }
  ]
  const [satisfiedListArr, setSatisfiedListArr] = useState(satisfiedCustomersArr)
  const switchList = (direction) => {
    setPressButton(direction)
    if (direction === "right") {
      satisfiedCustomersArr.unshift(satisfiedCustomersArr[satisfiedCustomersArr.length - 1])
      satisfiedCustomersArr.splice(satisfiedCustomersArr.length - 1, 1)
      setSatisfiedListArr(satisfiedCustomersArr)
    } else if (direction === "left") {
      satisfiedCustomersArr.push(satisfiedCustomersArr[0])
      satisfiedCustomersArr.splice(0, 1)
      setSatisfiedListArr(satisfiedCustomersArr)
    }
  }
  const videoDom = useRef(null)
  // const [videoPlay, setVideoPlay] = useState(false)
  const [videoMargin, setVideoMargin] = useState(6.77)
  const [maskShow, setMaskShow] = useState(false)
  const [maskTwo, setMaksTwo] = useState(true)
  const [popupFlag,setPopupFlag] = useState(true)
  useEffect(() => {
    const observer = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting) {
        videoDom.current.play()
      }
    }, {
      threshold: [0.25, 0.5, 0.75, 1]
    })
    observer.observe(videoDom.current)
    videoDom.current.addEventListener("pause", () => {
      setMaskShow(true)
    })
    videoDom.current.addEventListener("play", () => {
      setMaskShow(false)
    })
    setTimeout(()=>{
      setPopupFlag(false)
    },3000)
  }, [])

  const playVideo = () => {
    videoDom.current.play()
    setMaskShow(false)
  }

  return (
    <Fragment>
      <div className='home'>
        <Header />
        <div className='dtaas'>
          <div className='datas_left'>
            <Fade distance={"20%"} top duration={1300}  >
              <h3 style={{ overflow: "hidden" }}>DTaaS NG</h3>
            </Fade>
            <span>Upcoming Release in Q4 2023</span>
            <p>
              DTaaS NG is a Zero-copy cybersecurity framework that keeps your data safe and under your control. It enables
              data collaboration, value creation, analytics, and governance, all in one
              secure platform tailored for data-rich industries.
            </p>
            <div onClick={() => navigate("/contactUs")} className='button'>Subscribe to us</div>
          </div>
          <div className='datas_right'>
            <img src={datasImg} />
          </div>
        </div>
        <div className='video' style={{ margin: `0 ${videoMargin}` }} >
          <div className='mask mask2' style={{ display: maskTwo ? "block" : "none" }}>
            <div onClick={() => setMaksTwo(false)} className='button'>
              <img src={videoButton} alt="" />
            </div>
          </div>
          <div className='mask' style={{ display: maskShow ? "block" : "none" }}>
            <div onClick={playVideo} className='button'>
              <img src={videoButton} alt="" />
            </div>
          </div>
          <div className='video_box' >
            {/* <img src={video} /> */}
            <video ref={videoDom} muted width="100%" controls   >
              <source src={video} type="video/mp4" ></source>
            </video>
          </div>
        </div>
        {/* Datatrust for a connected community */}
        <div className='datatrust'>
          {/* top_part */}
          <ul className='top_part'>
            <li>

              <Fade distance={"20%"} top duration={1300} >
                <h3>Datatrust for
                  a connected community</h3></Fade>
            </li>
            <li>
              <p>Our Datatrust platform (DTaaS) allows the trusted sharing of data to enable smart city infrastructure and collaboration through Trusted Data Collaboration between your citizens, staff, and the city.</p>
              <div onClick={() => navigate("/company")}>
                <span>About us</span>
                <img src={arrowRight} />
              </div>
            </li>
          </ul>
          {/* bottom_part */}
          <ul className='bottom_part'>
            {
              productArr.map((item, index) => {
                return (
                  <li key={index}>
                    <div><img src={item.img} /></div>
                    <h3>{item.text}</h3>
                    <p>{item.p}</p>
                  </li>
                )
              })
            }
          </ul>
          {/* The  partners with us */}
        </div>
        {/* The  partners with us */}
        <div className='partners'>
          <div className='partners_text'>
            <Fade distance={"20%"} top duration={1300}><h3>The  partners with us</h3></Fade>

            <div>Provide comprehensive data protection for <span>more than 10 million customers</span></div>
          </div>
          <ul className='partners_img'>
            {
              partnersImgArr.map((item, index) => {
                return (
                  <li key={index}>
                    <img src={item} />
                  </li>
                )
              })
            }

          </ul>
        </div>
        {/* Sightline Innovation Security Products */}
        <div className='security_products'>
          <div className='security_products_text'>
            <Fade distance={"20%"} top duration={1300} ><h3>Open source technology, born for change</h3></Fade>
            <div>Create better technology with open source</div>
            <p>
              We believe that the open source development mode will help create more stable, safer and innovative technologies.
            </p>
          </div>
          <ul className='robot'>
            <li>
              <img src={robot} />
            </li>
            <li>
              <span>For decades</span>
              <Fade distance={"20%"} top duration={1300} ><h3>Why do we open source</h3></Fade>
              <p>We are committed to helping enterprises actively respond to technological changes in an open way</p>
            </li>
            <li>
              <div onClick={() => navigate("/productList")}>
                <img src={arrowRight} />
              </div>
            </li>
          </ul>
          <div className='securely'>
            {/* <h6>Sightline's feature</h6> */}
            <ul>
              {
                securelyCollaborateArr.map(item => {
                  return (
                    <li key={item.id}>
                      <div className='img'><img src={item.img} /></div>
                      <h3>{item.title}</h3>
                      <p>{item.text}</p>
                    </li>
                  )
                })
              }
            </ul>
          </div>
        </div>
        {/* Our best in class technology   */}
        <div className='technology'>
          <Fade distance={"20%"} top duration={1300}><h3>Our best in class technology  </h3></Fade>
          <p><span> to identify cyber attackers </span>, their plans and methods</p>

          <ul className='technology_list'>
            {
              technologyArr.map(item => {
                return (
                  <li key={item.id}>
                    <div className='img'><img src={item.img} /></div>
                    <div className='content'>
                      <h3>{item.h3}</h3>
                      <p>{item.p}</p>
                    </div>
                  </li>
                )
              })
            }
          </ul>
          <div className='technology_icon'>
            <ul>
              {
                iconArrTop.map((item, index) => {
                  return (
                    <li key={index}><img src={item} /></li>
                  )
                })
              }
            </ul>
            <ul>
              {
                iconArrBottom.map((item, index) => {
                  return (
                    <li key={index}><img src={item} /></li>
                  )
                })
              }
            </ul>
            <ul className='phone_icon'>
              {
                phoneIcon.map((item, index) => {
                  return (
                    <li key={index}><img src={item} /></li>
                  )
                })
              }
            </ul>
          </div>
        </div>
        {/* Satisfied customers */}
        {/* <div className='satisfied_customers'>
          <div className='satisfied_customers_text'>
            <Fade distance={"20%"} top duration={1300}><h3>Satisfied customers</h3></Fade>
            <p>Provide guarantee services for
              you to solve network security
              problems</p>
            <div onClick={() => navigate("/solutions")} className='more'>More<img src={arrowRight} /></div>
          </div>
          <div className='satisfied_customers_list'>
            <ul>
              {
                satisfiedListArr.map((item, index) => {
                  return (
                    <li key={index}>
                      <div className='img'><img src={item.img} /></div>
                      <div>
                        <h3>{item.h3}</h3>
                        <p>{item.p}</p>
                      </div>
                    </li>
                  )
                })
              }
            </ul>
          </div>
          <div className='bottom'>
            <div onClick={() => switchList("left")} className={pressButton === "left" ? "press" : "default"}>
              {
                pressButton === "left" ?
                  <img src={arrowGrey} style={{ transform: "rotate(180deg)" }} /> :
                  <img src={arrowBlue} />
              }
            </div>
            <div onClick={() => switchList("right")} className={pressButton === "right" ? "press" : "default"}>
              {
                pressButton === "right" ?
                  <img src={arrowGrey} /> :
                  <img style={{ transform: "rotate(180deg)" }} src={arrowBlue} />
              }
            </div>
          </div>
        </div> */}
        {/* What you can do with our all-in-one platform */}
        <div className='securely_collaborate'>
          <Fade distance={"20%"} top duration={1300} top><h3 id='platform' >What you can do with our all-in-one platform</h3></Fade>
          <text>provided several solutions on healthcare, smart city and finance service</text>
          <div className='securely_collaborate_content'>
            <div><img src={shieldImg} /></div>
            <Fade delay={100} distance={"20%"} top duration={1300}><h3>Securely collaborate</h3></Fade>
            <p>Our platform enables enterprise<br />
              datacollaboration which can be used to solve<br />
              complex problems by breakingdown data silos,<br />
              with full control of datarights and access</p>
            <div onClick={() => navigate("productList")} className='more'>More<img src={arrowRight} /></div>
          </div>
          <div className='phone'>
            <img src={phone} />
          </div>
          <div className='smart_home'>
            <img src={smartHome} />
          </div>
        </div>
        <div className='footer_logo'>
          <Fade distance={"20%"} top duration={1300}><h3>Start maximizing the value of your data today</h3></Fade>
          <div onClick={() => navigate("/contactUs")}>Get Started</div>
        </div>
      </div>
      <Footer />
      <div className="popup" style={{display:popupFlag?"block":"none"}}>
            <div className='content'>
                  <img className='bgimg' src={popupbg} alt="" />
                  <h3>Everything You Need to Vote</h3>
                  <p>Vote with or delegate your CTIO Tokens to help protect the integrity of the Maker protocol</p>
                  <Link target="_blank" to="https://www.chaintokenomics.io"><div className="button">Get Started</div></Link>
                  <img onClick={()=>setPopupFlag(false)} className='close' src={colse} alt="" />
            </div>
      </div>
    </Fragment>
  )
}

export default IndexPage
